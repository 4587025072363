import React, { useState, FC } from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { Button, useTheme } from '@mui/material';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { PageProps } from '../App';
import { VerifyOtpPayload, verifyOtp } from '../api';
import { AxiosError } from 'axios';

const OTP_LENGTH = 6;

interface OtpProps {
  setToken: (_token: string) => void;
}

const Otp: FC<PageProps & OtpProps> = ({
  setPage,
  userData,
  setToken,
  startExperience
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = React.useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const theme = useTheme();

  const handleChange = (newValue: string) => {
    setErrorMessage('');
    setOtp(newValue);
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);
    const payload: VerifyOtpPayload = {
      otp: otp
    };

    if (userData.phone) {
      payload.phone = `+${userData.phone}`;
    } else if (userData.email) {
      payload.email = userData.email;
    } else {
      throw new Error("Can't start experience without contact info!");
    }

    const tokenResponse = await verifyOtp(payload);

    if ('data' in tokenResponse) {
      if (tokenResponse.data.success) {
        setToken(tokenResponse.data.data.token);
      } else {
        setIsLoading(false);
        setErrorMessage(tokenResponse.data.message);
        return;
      }
    } else {
      if ((tokenResponse as AxiosError).isAxiosError) {
        setErrorMessage((tokenResponse as AxiosError).response?.data.message);
      } else {
        setErrorMessage(
          'Error encountered while processing OTP. Please contact administrator.'
        );
      }
      setIsLoading(false);
      return;
    }

    const res = await startExperience(tokenResponse.data.data.token);
    setIsLoading(false);

    if (!res) return;

    if ('status' in res && res.status === 200) {
      setPage('started');
    } else {
      setPage('register');
    }
  };

  const resendhandler = async () => {
    await startExperience();
  };

  const isNumeric = (text: string) => {
    return Boolean(text && !isNaN(Number(text)));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Typography fontWeight={600} marginBottom={1}>
        Verification
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{
          lineHeight: '1.3125rem',
          marginBottom: '30px'
        }}
      >
        We&apos;ve sent an OTP code to your email to verify this account
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%'
        }}
      >
        <Box
          component="form"
          onSubmit={submitHandler}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px'
            },
            width: '100%',
            gap: '30px'
          }}
        >
          <MuiOtpInput
            value={otp}
            onChange={handleChange}
            autoFocus
            validateChar={isNumeric}
            length={OTP_LENGTH}
            gap={2}
            sx={{
              '.MuiOutlinedInput-root': {
                width: '30px'
              },
              '.MuiOutlinedInput-input': {
                padding: '6px 0'
              },
              'fieldset.MuiOutlinedInput-notchedOutline': {
                borderWidth: '0 0 2px 0 !important',
                borderRadius: 0,
                borderColor: '#101010'
              }
            }}
          />
          {errorMessage && (
            <Typography variant="body2" color={'error'}>
              {errorMessage}
            </Typography>
          )}
          <Box
            display="flex"
            gap={{ xs: 0, sm: '20px' }}
            flexDirection={{ xs: 'column-reverse', sm: 'row' }}
            width={{ xs: '100%', sm: 'auto' }}
          >
            <LoadingButton
              type="submit"
              sx={{
                marginBottom: 2,
                height: '40px',
                width: { xs: '100%', sm: '190px' },
                borderRadius: '40px'
              }}
              color="secondary"
              variant="contained"
              loading={isLoading}
              disabled={otp.length !== OTP_LENGTH}
            >
              Verify
            </LoadingButton>
          </Box>
        </Box>
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="subtitle2" fontWeight={400}>
            Didn&apos;t receive the code?
          </Typography>
          <Button
            variant="text"
            onClick={resendhandler}
            disableRipple
            sx={{
              textTransform: 'none',
              textDecoration: 'underline',
              fontWeight: 400,
              color: '#101010',
              ':hover': {
                backgroundColor: 'inherit'
              }
            }}
          >
            Re-send
          </Button>
        </Box>
        {userData.phone ? (
          <Typography variant="subtitle2">SMS charges may apply</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default Otp;
