import * as React from 'react';
import { PageProps } from '../App';

type ModalContext = {
  handleClose?: (e: any, reason?: string) => void;
  handleReopen?: () => void;
  modalOpen?: boolean;
  onTransitionExited?: () => void;
  restartHandler?: () => void;
  postIframeSizeToParent?: (
    width: string,
    height: string,
    event: string
  ) => void;
  modalCloseTransitionEnded?: boolean;
  startExperience?: PageProps['startExperience'];
};

export const ModalContext = React.createContext<ModalContext>({});
