import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    secondary: {
      main: '#3D445A'
    }
  },
  typography: {
    fontFamily: `"Inter", sans-serif`
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          textTransform: 'capitalize'
        },
        outlined: {
          textTransform: 'capitalize',
          borderColor: '#3D445A'
        }
      }
    }
  }
});
