import React, { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Input from '../components/Input';
import { useTheme } from '@mui/material';
import { PageProps, SetUserDataValue, UserData } from '../App';
import PoweredByFormcut from '../components/PoweredByFormcut';

interface LandingProps {
  setUserData: (value: UserData | SetUserDataValue) => void;
}

const Landing: FC<PageProps & LandingProps> = ({
  setPage,
  userData,
  setUserData,
  startExperience
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);
    const res = await startExperience();
    setIsLoading(false);

    if (!res) return;

    if ('status' in res && res.status === 200) {
      setPage('started');
    } else {
      setPage('register');
    }
  };

  const emailHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserData((prev: UserData) => ({ ...prev, email: event.target.value }));
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <Typography fontWeight={600} marginBottom={1}>
        Do we have your measurements?
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{
          lineHeight: '1.3125rem',
          marginBottom: '30px'
        }}
      >
        Please enter your contact info. If we don&apos;t have your info, you
        will need to download our measuring app to collect your measurements
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%'
        }}
      >
        <Box
          component="form"
          onSubmit={submitHandler}
          display="flex"
          flexDirection="column"
          alignItems="center"
          sx={{
            [theme.breakpoints.down('sm')]: {
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px'
            },
            width: '100%'
          }}
        >
          <Input
            value={userData.email}
            onChange={emailHandler}
            label="Email"
            name="email"
            sx={{ marginBottom: 4, width: '100%' }}
            type="email"
            required
            disabled={isLoading}
          />
          <Box
            display="flex"
            gap={{ xs: 0, sm: '20px' }}
            flexDirection={{ xs: 'column-reverse', sm: 'row' }}
            width={{ xs: '100%', sm: 'auto' }}
          >
            <PoweredByFormcut />
            <LoadingButton
              type="submit"
              sx={{
                marginBottom: 2,
                height: '40px',
                width: { xs: '100%', sm: '190px' },
                borderRadius: '40px'
              }}
              color="secondary"
              variant="contained"
              loading={isLoading}
            >
              Continue
            </LoadingButton>
          </Box>
        </Box>
        {userData.phone ? (
          <Typography variant="subtitle2">SMS charges may apply</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default Landing;
