import React, { useState, FC, useEffect, useRef, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { UserData } from '../App';
import formcutLogo from '../assets/formcut-logo-light.svg';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Button from '@mui/material/Button';
import { ModalContext } from '../context/ModalContext';
import connectIcon from '../assets/connect-icon.svg';
import LoadingButton from '@mui/lab/LoadingButton';
import { timeAgo } from '../utils/time';

interface Message {
  isSuccess: boolean;
  text: string;
}
interface AccountFloatingButtonProps {
  userData: UserData;
  scanFound: boolean;
}

const MOBILE_SCREEN = 450;

const AccountFloatingButton: FC<AccountFloatingButtonProps> = ({
  userData,
  scanFound
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [hideDropdown, setHideDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<Message | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const containerRef = useRef<any>();
  const isUserConnected = userData.userId;
  const modalContext = useContext(ModalContext);
  const isMobile = screenWidth <= MOBILE_SCREEN;
  const handleExpandToggle = () => {
    setIsExpanded((e) => !e);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (!modalContext.modalCloseTransitionEnded) return;

    const w = isMobile ? '100%' : '240px';

    if (isExpanded) {
      let h = '240px';
      if (scanFound) {
        h = '270px';
      }
      if (message) {
        if (message.isSuccess) {
          h = '325px';
        } else {
          h = '345px';
        }
      }

      modalContext.postIframeSizeToParent &&
        modalContext.postIframeSizeToParent(w, h, 'accountBtnCollapsed');
      setHideDropdown(false);
    } else {
      const h = '47px';
      timeout = setTimeout(() => {
        modalContext.postIframeSizeToParent &&
          modalContext.postIframeSizeToParent(w, h, 'accountBtnExpanded');
        setHideDropdown(true);
      }, 400);
    }

    return () => timeout && clearTimeout(timeout);
  }, [isExpanded, modalContext.modalCloseTransitionEnded, message]);

  const handleDisconnect = () => {
    setIsExpanded(false);
    setHideDropdown(true);
    setMessage(null);
    modalContext.restartHandler && modalContext.restartHandler();
  };

  const handleReScan = async () => {
    if (!modalContext.startExperience) return;
    setIsLoading(true);
    const res = await modalContext.startExperience();
    setIsLoading(false);

    if (!res) return;

    if ('status' in res && res.status === 200) {
      setMessage({
        isSuccess: true,
        text: "We've sent you an email to proceed with the new scan."
      });
    } else {
      setMessage({
        isSuccess: false,
        text: 'Oops. We experienced an error sending you a scan invite. Please try again.'
      });
    }
  };

  return (
    <Box
      position="absolute"
      top="0px"
      right="0px"
      left={isMobile ? '0px' : undefined}
      display="flex"
      flexWrap="wrap"
    >
      <Box
        display="grid"
        gridTemplateRows={isExpanded ? 'min-content 1fr' : 'min-content 0fr'}
        width={isMobile ? '100%' : '238px'}
        bgcolor="white"
        border="1px solid #e7e7e7"
        borderRadius="6px"
        sx={{
          transition:
            'grid-template-rows cubic-bezier(0.25, 0.46, 0.45, 0.94) 400ms'
        }}
        ref={containerRef}
      >
        <Box height="36px" position="relative">
          <Box
            position="absolute"
            top="0"
            left="-1px"
            height="46px"
            width={isMobile ? '100%' : '240px'}
            bgcolor={'secondary.main'}
            color="white"
            borderRadius="0px 0px 10px 10px"
            display="flex"
            gap="6px"
            alignItems="center"
            justifyContent={isMobile ? 'center' : 'space-between'}
            padding="8px 24px"
            boxSizing="border-box"
          >
            <img src={formcutLogo} width="85px" />
            {isUserConnected ? (
              <>
                <Typography variant="body2">Connected</Typography>
                <IconButton
                  aria-label="open"
                  onClick={handleExpandToggle}
                  sx={{
                    color: '#e0e1e4',
                    border: '1px solid #a2a5b0',
                    bgcolor: '#64697b',
                    width: '20px',
                    height: '20px'
                  }}
                  disableRipple
                  size="small"
                >
                  <MoreVertIcon
                    sx={{
                      height: '16px',
                      transition: 'rotate linear 160ms',
                      rotate: isExpanded ? '0deg' : '-90deg'
                    }}
                  />
                </IconButton>
              </>
            ) : (
              <>
                <Typography variant="body2">Connect</Typography>
                <IconButton
                  aria-label="connect"
                  onClick={modalContext.handleReopen}
                  sx={{
                    color: 'white'
                  }}
                  disableRipple
                  size="small"
                >
                  <img src={connectIcon} />
                </IconButton>
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            transition: 'padding-top 400ms, padding-bottom 400ms'
          }}
          padding={isExpanded ? '22px 12px 8px 12px' : '0px 12px 0px 12px'}
          overflow={'hidden'}
        >
          {isUserConnected && (isExpanded || !hideDropdown) && (
            <>
              <Typography variant="subtitle1" fontSize={'12px'} color="#707070">
                Welcome
              </Typography>
              <Typography fontWeight={600} fontSize={16} marginBottom={'2px'}>
                {(userData.firstName + ' ' + userData.lastName).trim()}
              </Typography>
              {scanFound ? (
                <>
                  <Typography
                    variant="body2"
                    textAlign="center"
                    borderRadius="20px"
                    fontWeight={600}
                    color="white"
                    bgcolor="#09A876"
                    p="3px 17px"
                    lineHeight="18px"
                    marginBottom="10px"
                    width="fit-content"
                  >
                    Ready to fit
                  </Typography>
                  <Typography variant="body2" marginBottom={'10px'}>
                    Last Scan:{' '}
                    {userData.scanDate ? timeAgo(userData.scanDate) : 'unknown'}
                  </Typography>{' '}
                </>
              ) : (
                <Typography
                  variant="body2"
                  textAlign="center"
                  borderRadius="20px"
                  fontWeight={600}
                  color="white"
                  bgcolor="#CFA83D"
                  p="3px 17px"
                  lineHeight="18px"
                  marginBottom="10px"
                  width="fit-content"
                >
                  waiting for scan
                </Typography>
              )}
              {message && (
                <Box
                  borderRadius="8px"
                  bgcolor={message.isSuccess ? '#ececee' : '#fee5e5'}
                  padding="8px 4px"
                >
                  <Typography variant="body2" textAlign="center">
                    {message.text}
                  </Typography>
                </Box>
              )}
              <LoadingButton
                variant="contained"
                onClick={handleReScan}
                loading={isLoading}
                sx={{
                  borderRadius: '8px',
                  marginTop: '12px',
                  textTransform: 'none',
                  boxShadow: 'none',
                  width: '100%',
                  '&:hover': {
                    boxShadow: 'none'
                  }
                }}
                color="secondary"
              >
                Re-Scan
              </LoadingButton>
              <Button
                variant="text"
                color="secondary"
                onClick={handleDisconnect}
                sx={{
                  borderRadius: '8px',
                  textTransform: 'none',
                  width: '100%',
                  marginTop: '5px',
                  paddingLeft: '16px',
                  textDecoration: 'underline',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Disconnect
              </Button>
            </>
          )}
        </Box>
      </Box>
      <Box width="100px" minWidth="0px" />
    </Box>
  );
};

export default AccountFloatingButton;
