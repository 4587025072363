import { FC, ReactNode, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { blueGrey } from '@mui/material/colors';
import { useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ModalContext } from '../context/ModalContext';
import AccountFloatingButton from './AccountFloatingButton';

import { inIframe } from '../in-iframe';
import { PageTypes, UserData } from '../App';

interface MainTemplateProps {
  children: ReactNode;
  restartHandler: () => void;
  currentPage: PageTypes;
  isEssView: boolean;
  userData: UserData;
  scanFound: boolean;
}

const MainTemplate = ({
  children,
  currentPage,
  restartHandler,
  isEssView,
  userData,
  scanFound
}: MainTemplateProps) => {
  const theme = useTheme();
  const isInIframe = inIframe();
  const modalContext = useContext(ModalContext);

  return (
    <>
      {!isEssView && (
        <AccountFloatingButton userData={userData} scanFound={scanFound} />
      )}
      <Dialog
        onClose={modalContext.handleClose}
        disableEscapeKeyDown={isEssView}
        aria-labelledby="customized-dialog-title"
        open={modalContext.modalOpen!}
        sx={{
          '& .MuiModal-backdrop': {
            backdropFilter: 'blur(4px)',
            transitionProperty: 'opacity,backdrop-filter'
          }
        }}
        PaperProps={{
          sx: {
            borderRadius: '8px',
            width: '100%',
            [theme.breakpoints.up('sm')]: {
              width: isInIframe ? 500 : 490
            }
          }
        }}
        TransitionProps={{ onExited: modalContext.onTransitionExited }}
      >
        {!isEssView && (
          <IconButton
            aria-label="close"
            onClick={modalContext.handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500]
            }}
            disableRipple
            size="small"
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent
          sx={{
            // margin: isInIframe ? '0 auto 20px' : '0 0 20px',
            padding: '40px 24px',
            width: 'auto'
          }}
        >
          {!isInIframe && (
            <Box
              component="img"
              sx={{
                maxWidth: 300,
                [theme.breakpoints.down('sm')]: {
                  fontSize: '40px',
                  padding: '60px 0 0 30px'
                }
              }}
              src="/logo.png"
            />
          )}
          <Box
            sx={{
              background: isInIframe ? '#fff' : blueGrey[50],
              padding: '20px 0 0 0',
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
              // [theme.breakpoints.up('sm')]: {
              //   padding: '30px 60px'
              // }
            }}
          >
            {children}
            {currentPage !== 'landing' && (
              <Button
                variant="text"
                onClick={restartHandler}
                disableRipple
                sx={{
                  textTransform: 'none',
                  textDecoration: 'underline',
                  fontWeight: 400,
                  color: '#101010',
                  ':hover': {
                    backgroundColor: 'inherit'
                  }
                }}
              >
                Not you?
              </Button>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MainTemplate;
