import React from 'react';
import { styled } from '@mui/material/styles';

const Loading = styled('div')(({ theme }) => ({
  width: '50px',
  margin: 'auto',
  marginTop: '10rem',
  marginBottom: '10rem',
  aspectRatio: '1',
  borderRadius: '50%',
  background: `radial-gradient(farthest-side, #505050 94%, #0000) top/8px 8px no-repeat,conic-gradient(#0000 30%, #505050)`,
  WebkitMask: 'radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0)',
  animation: 'l13 1s infinite linear',
  '@keyframes l13': {
    '100%': {
      transform: `rotate(1turn)`
    }
  }
}));

export default Loading;
