import React from 'react';
import Box from '@mui/material/Box';

import formcutLogo from '../assets/formcut-logo-dark.svg';
import { Typography } from '@mui/material';

const PoweredByFormcut = () => {
  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'row', sm: 'column' }}
      alignItems={{ xs: 'baseline', sm: 'flex-end' }}
      justifyContent={{ xs: 'center', sm: 'normal' }}
      gap={{ xs: '8px', sm: '0' }}
    >
      <Typography variant="body2">powered by</Typography>
      <img src={formcutLogo} />
    </Box>
  );
};

export default PoweredByFormcut;
