import React, { useState, FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Input from '../components/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import LoadingButton from '@mui/lab/LoadingButton';
import { registerUser } from '../api';
import { PageProps } from '../App';
import { AxiosError } from 'axios';

interface RegisterProps extends PageProps {
  setToken: (_token: string) => void;
  setUserData: React.Dispatch<React.SetStateAction<any>>;
}

const Register: FC<RegisterProps> = ({
  setPage,
  userData,
  startExperience,
  setToken,
  setUserData
}) => {
  const [value, setValue] = useState({
    firstName: '',
    lastName: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((prev) => ({ ...prev, [event.target.name]: event.target.value }));
    setErrorMessage('');
  };

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);
    let res;
    if (userData.phone) {
      res = await registerUser({ ...value, phone: `+${userData.phone}` });
    } else {
      res = await registerUser({ ...value, email: userData.email });
    }

    if ('data' in res) {
      if (res.data.success) {
        setToken(res.data.data.token);
        const ud = {
          userId: res.data.data.userData.id,
          ...res.data.data.userData
        };
        setUserData(ud);
        const startSessionResponse = await startExperience(
          res.data.data.token,
          ud
        );
        setIsLoading(false);

        if (!startSessionResponse) return;

        if (
          'status' in startSessionResponse &&
          startSessionResponse.status === 200
        ) {
          setPage('started');
        } else {
          setPage('landing');
          throw 'Could not start mobile experience for newly created user';
        }
      } else {
        setErrorMessage(res.data.message);
      }
    } else {
      if ((res as AxiosError).isAxiosError) {
        setErrorMessage((res as AxiosError).response?.data.message);
      } else {
        setErrorMessage(
          'Error encountered while registering. Please contact administrator.'
        );
      }
    }

    setIsLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography fontWeight={600} marginBottom={2} textTransform="capitalize">
        Nice to meet you!
      </Typography>
      <Typography
        variant="body2"
        textAlign="center"
        sx={{
          margin: '0 auto 20px',
          lineHeight: '1.4'
        }}
      >
        Just a little more, and we can continue!
      </Typography>
      <Box
        sx={{
          margin: '0 auto',
          textAlign: 'center'
        }}
      >
        <form onSubmit={submitHandler}>
          <Input
            value={value.firstName}
            onChange={changeHandler}
            label="First Name"
            name="firstName"
            sx={{ marginBottom: 2, width: '100%' }}
            required
            disabled={isLoading}
          />
          <Input
            value={value.lastName}
            onChange={changeHandler}
            label="Last Name"
            sx={{ marginBottom: 2, width: '100%' }}
            name="lastName"
            required
            disabled={isLoading}
          />
          <FormGroup sx={{ marginBottom: 3 }}>
            <FormControlLabel
              control={<Checkbox required disabled={isLoading} disableRipple />}
              label={
                <Box sx={{ fontSize: '14px', textAlign: 'left' }}>
                  I agree to the{' '}
                  <Link
                    target="_blank"
                    href="https://www.sizestream.com/mobile-app-terms-and-conditions"
                  >
                    terms and conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    target="_blank"
                    href="https://www.sizestream.com/privacypolicy"
                  >
                    privacy policy
                  </Link>
                </Box>
              }
            />
          </FormGroup>
          {errorMessage && (
            <Typography variant="body2" color={'error'}>
              {errorMessage}
            </Typography>
          )}
          <LoadingButton
            type="submit"
            sx={{
              marginBottom: 3,
              height: '40px',
              width: '190px',
              borderRadius: '40px'
            }}
            color="secondary"
            variant="contained"
            loading={isLoading}
          >
            Continue
          </LoadingButton>
        </form>
        {userData.phone ? (
          <Typography variant="subtitle2">SMS charges may apply</Typography>
        ) : null}
      </Box>
    </Box>
  );
};

export default Register;
