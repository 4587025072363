import React, { FC, useState, useEffect, useRef, useContext } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { PageProps } from '../App';
import { timeAgo } from '../utils/time';
import { ModalContext } from '../context/ModalContext';
import measurementSvg from '../assets/image/measurement.svg';

const RESEND_COOLDOWN_SECONDS = 10;

interface StartedProps extends PageProps {
  scanFound: boolean;
}

const Started: FC<StartedProps> = ({
  setPage,
  userData,
  startExperience,
  scanFound
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(0);
  const intervalRef = useRef<number | undefined>();
  const modalContext = useContext(ModalContext);

  const submitHandler = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    startCountdown();

    setIsLoading(true);
    const res2 = await startExperience();
    setIsLoading(false);

    if (!res2) return;

    if (res2 && 'status' in res2 && res2.status === 200) {
      setPage('started');
    } else {
      setPage('landing');
      throw 'Could not resend';
    }
  };

  const startCountdown = () => {
    setResendCooldown(RESEND_COOLDOWN_SECONDS);

    intervalRef.current = window.setInterval(() => {
      setResendCooldown((cooldown) => {
        if (cooldown === 1) clearInterval(intervalRef.current);
        return cooldown - 1;
      });
    }, 1000);
  };

  // Clean up interval if the component unmounts before it gets to 0
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      // sx={{
      //   textAlign: 'center'
      // }}
    >
      <Typography fontWeight={600} marginBottom={2} textTransform="capitalize">
        Welcome {userData.firstName} {userData.lastName}
      </Typography>
      {scanFound ? (
        <>
          <Typography
            variant="body2"
            textAlign="center"
            marginBottom="12px"
            sx={{
              // margin: '0 auto 40px',
              lineHeight: '1.4'
            }}
          >
            Last scan:{' '}
            {userData.scanDate ? timeAgo(userData.scanDate) : 'unknown'}
          </Typography>
          <Typography
            variant="body2"
            textAlign="center"
            borderRadius="20px"
            fontWeight={600}
            color="white"
            bgcolor="#09A876"
            p="3px 17px"
            lineHeight="18px"
            marginBottom="30px"
          >
            Ready to fit
          </Typography>
          <LoadingButton
            type="button"
            sx={{
              marginBottom: 3,
              height: '40px',
              width: '190px',
              borderRadius: '40px'
            }}
            color="secondary"
            variant="contained"
            loading={isLoading}
            onClick={modalContext.handleClose}
          >
            Continue
          </LoadingButton>
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            textAlign="center"
            sx={{
              margin: '0 auto 20px',
              lineHeight: '1.4'
            }}
          >
            Please open the link sent to you on your mobile phone to download
            our measuring app. Follow the instructions and we&apos;ll continue
            here once you have finished measuring.
          </Typography>
          <Box
            component={'img'}
            src={measurementSvg}
            sx={{ marginBottom: '20px' }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center'
            }}
          >
            <form onSubmit={submitHandler}>
              <LoadingButton
                type="submit"
                sx={{
                  marginBottom: 2,
                  background: '#fff',
                  borderRadius: '40px'
                }}
                color="inherit"
                variant="outlined"
                loading={isLoading}
                disabled={resendCooldown > 0}
              >
                Re-Send Instructions {resendCooldown > 0 ? resendCooldown : ''}
              </LoadingButton>
            </form>
            {userData.phone ? (
              <Typography variant="subtitle2">SMS charges may apply</Typography>
            ) : null}
          </Box>
        </>
      )}
      {/* {!scanFound && (
        <Typography>
          This page will automatically update when scan is found.
        </Typography>
      )} */}
    </Box>
  );
};

export default Started;
