import { useState, useRef, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import Landing from './pages/Landing';
import Register from './pages/Register';
import Started from './pages/Started';
import ErrorPage from './pages/ErrorPage';
import backgroundImg from './assets/image/background.png';
import { useTheme } from '@mui/material';
import {
  startMobileExperience,
  StartMobileExperiencePayload,
  findUser,
  getLatestScan,
  sendOtp,
  SendOtpPayload,
  FindUserPayload
} from './api';
import { AxiosResponse } from 'axios';
import { inIframe } from './in-iframe';
import MainTemplate from './components/MainTemplate';
import { ModalContext } from './context/ModalContext';
import Loading from './pages/Loading';
import Otp from './pages/Otp';
import { convertNullToUndefined } from './utils/object';

export type PageTypes =
  | 'loading'
  | 'landing'
  | 'register'
  | 'otp'
  | 'started'
  | 'error';
export type SetUserDataValue = (prev: UserData) => UserData;

export interface PageProps {
  setPage: (value: PageTypes) => void;
  userData: UserData;
  startExperience: (
    _token?: string,
    ud?: any
  ) => Promise<Error | AxiosResponse<null, any> | undefined>;
}

export type UserData = {
  email: string;
  phone: string;
  firstName?: string;
  lastName?: string;
  scanId?: string;
  userId?: string;
  scanDate?: string;
};

type searchParamHashType = {
  [key: string]: string;
};

const searchParamHash: searchParamHashType = {
  appid: 'appId'
};

const Router = () => {
  const defaultUserData = { email: '', phone: '' };
  const queryParams = new URLSearchParams(window.location.search);
  const isEssView = queryParams.get('view') === 'ess-only';
  const [currentPage, _setPage] = useState<PageTypes>('loading');
  const [userData, setUserData] = useState<UserData>(defaultUserData);
  const [scanFound, setScanFound] = useState(false);
  const [modalOpen, setModalOpen] = useState(true);
  const [modalCloseTransitionEnded, setModalCloseTransitionEnded] =
    useState(false);
  const [token, _setToken] = useState<string | null>(null);
  const interval = useRef<NodeJS.Timer | undefined>();
  const userDataPosted = useRef(false);

  const setPage = (page: PageTypes) => {
    if (page === 'landing') setUserData(defaultUserData);
    return _setPage(page);
  };

  const setToken = (_token: string | null) => {
    _setToken(_token);
    storeDataInParentLocalStorage('userToken', _token);
  };

  const startExperience = useCallback(
    async (_token?: string, _userData?: UserData) => {
      const contactPayload:
        | StartMobileExperiencePayload
        | SendOtpPayload
        | FindUserPayload = {};

      const ud = _userData || userData;

      if (ud.phone) {
        contactPayload.phone = `+${ud.phone}`;
      } else if (ud.email) {
        contactPayload.email = ud.email;
      } else {
        throw new Error("Can't start experience without contact info!");
      }

      const alteredSearchParams = Array.from(
        new URLSearchParams(location.search).entries()
      ).map(([key, value]: [string, string]) => {
        const lowerCasedKey = key.toLowerCase();

        return [searchParamHash[lowerCasedKey] || key, value];
      });

      const searchParams = Object.fromEntries(alteredSearchParams);

      if (
        searchParams.appId &&
        !/[a-f\d]{8}-[a-f\d]{4}-4[a-f\d]{3}-[89ab][a-f\d]{3}-[a-f\d]{12}/.test(
          searchParams.appId
        )
      ) {
        setPage('error');
        return;
      }

      if (!(_token || token)) {
        const res = await sendOtp(contactPayload);
        if ('data' in res && res.data.success) {
          setPage('otp');
        } else {
          setPage('register');
        }
        return;
      }

      const authPayload = { token: (_token || token)! };

      if (!ud.userId) {
        const findUserResponse = await findUser(authPayload);
        let _ud;
        if ('data' in findUserResponse && findUserResponse.data.success) {
          const { id, ...rest } = findUserResponse.data.data;
          _ud = { ...ud, userId: id, ...rest };
          _ud = convertNullToUndefined(_ud);
          if (!_ud.email) {
            console.error('Falsy email value encountered', _ud);
            return;
          }
          setUserData(_ud);

          if (_ud.scanId) {
            setScanFound(true);
            setPage('started');
            postUserDataToParent({ ..._ud });
            return;
          }
        }
      }

      const response = await startMobileExperience(authPayload);

      return response;
    },
    [token, userData]
  );

  const getCurrentPage = () => {
    switch (currentPage) {
      case 'loading':
        return <Loading />;
      case 'landing':
        return (
          <Landing
            setPage={setPage}
            userData={userData}
            setUserData={setUserData}
            startExperience={startExperience}
          />
        );
      case 'otp':
        return (
          <Otp
            setPage={setPage}
            userData={userData}
            setToken={setToken}
            startExperience={startExperience}
          />
        );
      case 'register':
        return (
          <Register
            setPage={setPage}
            userData={userData}
            setToken={setToken}
            setUserData={setUserData}
            startExperience={startExperience}
          />
        );
      case 'started':
        return (
          <Started
            setPage={setPage}
            userData={userData}
            startExperience={startExperience}
            scanFound={scanFound}
          />
        );
      case 'error':
        return <ErrorPage />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!scanFound && userData.userId && token) {
      // Storing it now so that the polling could be resumed
      storeDataInParentLocalStorage('userData', userData);
      if (interval.current) {
        clearInterval(interval.current);
      }

      interval.current = setInterval(async () => {
        const response = await getLatestScan({ token });
        if (
          'data' in response &&
          response.data.success &&
          response.data.data.scanId
        ) {
          setUserData((ud: any) => ({
            ...ud,
            scanId: response.data.data.scanId
          }));
          setScanFound(true);
        }
      }, 30000);
    }

    if (scanFound && interval.current) {
      postUserDataToParent(userData);
      clearInterval(interval.current);
      interval.current = undefined;
    }

    return () => interval.current && clearInterval(interval.current);
  }, [userData, scanFound]);

  useEffect(() => {
    const handleMessage = async (e: MessageEvent) => {
      if (e.source !== window.parent) return;

      if (
        e.data.action === 'returnRetrievedData' &&
        e.data.body.hasOwnProperty('userToken')
      ) {
        if (e.data.body.userToken && e.data.body.userToken !== 'null') {
          const authPayload = { token: e.data.body.userToken };
          setToken(e.data.body.userToken);

          const findUserResponse = await findUser(authPayload);
          let ud;
          if ('data' in findUserResponse && findUserResponse.data.success) {
            const { id, ...rest } = findUserResponse.data.data;
            ud = { userId: id, ...rest };
            ud = convertNullToUndefined(ud);
            setUserData(ud);
            postUserDataToParent(ud);

            if (ud.scanId) {
              setScanFound(true);
              handleModalClose();
            } else {
              setPage('started');
            }
          } else {
            setPage('landing');
          }
        } else {
          setPage('landing');
        }
      }
    };

    window.addEventListener('message', handleMessage);

    // TODO: add support for formcut_token
    if (
      queryParams.has('formcut_email') &&
      queryParams.has('formcut_scan_id')
    ) {
      // clear the local storage of old user data
      storeDataInParentLocalStorage('userData', null);
      storeDataInParentLocalStorage('userToken', null);

      (async () => {
        // Get user data (validate email is correct)
        const payload: FindUserPayload = {
          email: queryParams.get('formcut_email') as string
        };

        const findUserResponse = await findUser(payload);
        let ud;
        if ('data' in findUserResponse && findUserResponse.data.success) {
          const { id, userToken, ...rest } = findUserResponse.data.data;

          ud = {
            userId: id,
            ...rest,
            // Trust scan id passed in query param
            scanId: queryParams.get('formcut_scan_id') as string
          };
          ud = convertNullToUndefined(ud);
          setUserData(ud);
          if (userToken) {
            setToken(userToken);
          }

          if (ud.scanId) {
            setScanFound(true);
            postUserDataToParent({ ...ud });
          }
        } else {
          setPage('landing'); // Or should it be register?
        }
      })();
    } else {
      // window.parent.postMessage(
      //   { action: 'retrieveData', body: { key: 'userData' } },
      //   '*'
      // );
      window.parent.postMessage(
        { action: 'retrieveData', body: { key: 'userToken' } },
        '*'
      );
    }

    return () => window.removeEventListener('message', handleMessage);
  }, []);

  const postUserDataToParent = (
    ud: any,
    storeInLocalStorage = true,
    forceSend = false
  ) => {
    // TODO: remove * and add specific target origin
    if (!userDataPosted.current || forceSend) {
      window.parent.postMessage(
        { action: 'setUserData', body: { userData: ud } },
        '*'
      );
      userDataPosted.current = true;
    }

    if (storeInLocalStorage) {
      storeDataInParentLocalStorage('userData', ud);
    }
  };

  const storeDataInParentLocalStorage = (key: string, data: any) => {
    window.parent.postMessage(
      {
        action: 'storeData',
        body: {
          key,
          value: typeof data === 'string' ? data : JSON.stringify(data)
        }
      },
      '*'
    );
  };

  const restartHandler = () => {
    setPage('landing');
    setScanFound(false);
    setUserData(defaultUserData);
    postUserDataToParent({}, false, true);
    storeDataInParentLocalStorage('userData', null);
    setToken(null);
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = undefined;
    }
  };

  const handleModalClose = (e?: any, reason?: string) => {
    if (isEssView && reason === 'backdropClick') return;
    setModalOpen(false);
  };

  const onTransitionExited = () => {
    setModalCloseTransitionEnded(true);
  };

  const handleModalReopen = () => {
    setModalCloseTransitionEnded(false);
    setModalOpen(true);
    postIframeSizeToParent('100%', '100%', 'modalOpen');
  };

  const postIframeSizeToParent = (
    width: string,
    height: string,
    event: string
  ) => {
    window.parent.postMessage(
      {
        action: 'essIframeResize',
        body: {
          width,
          height,
          event
        }
      },
      '*'
    );
  };

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        modalCloseTransitionEnded,
        handleClose: handleModalClose,
        handleReopen: handleModalReopen,
        onTransitionExited,
        restartHandler,
        postIframeSizeToParent,
        startExperience
      }}
    >
      <MainTemplate
        currentPage={currentPage}
        restartHandler={restartHandler}
        isEssView={isEssView}
        userData={userData}
        scanFound={scanFound}
      >
        {getCurrentPage()}
      </MainTemplate>
    </ModalContext.Provider>
  );
};

const App = () => {
  const theme = useTheme();

  useEffect(() => {
    window.addEventListener('message', async (event) => {
      console.log('message received in ESS', event);
    });
  }, []);

  if (inIframe()) return <Router />;

  return (
    <Box>
      <Grid container sx={{ height: '100vh' }}>
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Router />
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            [theme.breakpoints.down('md')]: {
              display: 'none'
            }
          }}
        >
          <Box
            sx={{
              position: 'relative',
              img: {
                width: '100%',
                height: '100vh',
                objectFit: 'cover'
              },
              '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                display: 'inline-block',
                width: '100%',
                height: '100%',
                opacity: '0.6',
                background: '#fff'
              }
            }}
          >
            <img src={backgroundImg} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default App;
